/**
 * Created by Alexandru Huszar on 3/4/2021.
 */
import React from 'react';

import Layout, { LayoutTitle } from 'components/Layout';
import SeoHelmet from 'components/SeoHelmet';
import PhotosQuery from 'components/Queries/PhotosQuery';

/**
 * poze
 */
function Photos() {
  /**
   * Render the component
   *
   * @return {JSX.Element}
   */
  return (
    <Layout>
      <SeoHelmet title="Galeria de poze" />
      <LayoutTitle title=" Galeria de poze" />
      <PhotosQuery filter="portofoliu" />
    </Layout>
  );
}

Photos.defaultProps = {
  classes: {},
};

export default Photos;
